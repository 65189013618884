<template >
    <section :class="'page-section section-faq'" :style="{ height: 'auto' }">
        <div class="container" :style="{ height: 'auto' }">
            <div class="cards-wrapper cards-col-1" :style="{ height: 'auto' }">
                <div class="card-element" :style="{ height: 'auto' }">
                    <div class="card-wrapper" :style="{ height: 'auto' }">
                        <div class="card-content" :style="{ height: 'auto' }">
                            <ul v-if="currentLanguage === 'pl'" class="faq-list" :style="{ height: 'auto' }">
                                <li v-for="(item, index) in  faqList.questions_pl" :key="index" :style="{ height: 'auto' }">
                                    <h5 class="question">
                                        <a @click="toggleAnswerPl(index)"
                                            :class="{ 'minus-icon': !item.active, 'plus-icon': item.active }">
                                                <div v-html="item.question" />
                                        </a>
                                    </h5>
                                    <div v-if="!item.active" class="answer">

                                        <div v-html="item.answer" />


                                    </div>
                                </li>
                            </ul>

                            <ul v-if="currentLanguage === 'en'" class="faq-list" :style="{ height: 'auto' }">
                                <li v-for="(item, index) in faqList.questions_en" :key="index" :style="{ height: 'auto' }">
                                    <h5 class="question" style="font-size: 18px;">
                                        <a @click="toggleAnswerEn(index)"
                                            :class="{ 'minus-icon': !item.active, 'plus-icon': item.active }" style="font-size: 18px;">
                                                <div v-html="item.question"  />
                                        </a>
                                    </h5>
                                    <div v-if="!item.active" class="answer">

                                        <div v-html="item.answer" />


                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            faqList: [],
        };
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    methods: {


        toggleAnswerEn(index) {
            this.faqList.questions_en[index] = { ...this.faqList.questions_en[index], active: !this.faqList.questions_en[index]?.active };
        },
        toggleAnswerPl(index) {
            this.faqList.questions_pl[index] = { ...this.faqList.questions_pl[index], active: !this.faqList.questions_pl[index]?.active };
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/faq-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.faqList = response.data || { items_pl: [], questions_pl: [], items_en: [], questions_en: [] };
            })
            .catch(error => {
                console.log('Error fetching FAQ QA data:', error);
            });

        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
};
</script>

<style>
@import '@/css/main.css';

.minus-icon::before {
    margin-right: 5px;
    background-image: url('../assets/icons/icon_minus@2x.png');
    background-repeat: no-repeat;
    background-size: 11px;
    background-position: center;
}

.plus-icon::before {
    margin-right: 5px;
    background-image: url('../assets/icons/icon_plus@2x.png');
    background-repeat: no-repeat;
    background-size: 11px;
    background-position: center;
}

li p {
    color: black;
    text-transform: none;
}


</style>
