<template>
    <NavbarMain />

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl"
        :content="first_section_content_pl" />
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en"
        :content="first_section_content_en" />

    <GeneralContactForm />

    <div class="columns-4-section">
        <PhotoCardsHeader v-if="currentLanguage === 'pl'" :section_title="third_section_title_pl"
            :section_content="third_section_content_pl" />
        <PhotoCardsHeader v-else :section_title="third_section_title_en" :section_content="third_section_content_en" />

        <PhotoCards v-if="currentLanguage === 'pl'" :section_image="third_section_box_image_pl"
            :button_title="third_section_box_title_pl" :button_text="third_section_box_content_pl"
            :button_link="third_section_box_link_pl" />
        <PhotoCards v-else :section_image="third_section_box_image_en" :button_title="third_section_box_title_en"
            :button_text="third_section_box_content_en" :button_link="third_section_box_link_en"/>
    </div>
    
    <FooterPage />
</template>

<script>
import axios from 'axios';

import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import GeneralContactForm from '@/components/GeneralContactForm.vue';
import PhotoCards from '@/components/PhotoCards.vue';
import PhotoCardsHeader from '@/components/PhotoCardsHeader.vue';

export default {
    components: {
        PresentationSection,
        NavbarMain,
        FooterPage,
        GeneralContactForm,
        PhotoCards,
        PhotoCardsHeader
    },
    data() {
        return {
            contactUsData: {
                items_pl: [{ first_section_image: '', first_section_title: '', first_section_content: '', second_section_title: '', second_section_content: '', third_section_title: '', third_section_content: '', third_section_box_image: '', third_section_box_title: '', third_section_box_content: '', third_section_box_link: '', forth_section_title: '', forth_section_content: '', fifth_section_title: '', fifth_section_content: '' }],
                items_en: [{ first_section_image: '', first_section_title: '', first_section_content: '', second_section_title: '', second_section_content: '', third_section_title: '', third_section_content: '', third_section_box_image: '', third_section_box_title: '', third_section_box_content: '', third_section_box_link: '', forth_section_title: '', forth_section_content: '', fifth_section_title: '', fifth_section_content: '' }]
            },

            first_section_image_pl: '',
            first_section_title_pl: '',
            first_section_content_pl: '',
            second_section_title_pl: '',
            second_section_content_pl: '',
            third_section_title_pl: '',
            third_section_content_pl: '',
            third_section_box_image_pl: '',
            third_section_box_title_pl: '',
            third_section_box_content_pl: '',
            third_section_box_link_pl: '',
            fourth_section_title_pl: '',
            fourth_section_content_pl: '',
            fifth_section_title_pl: '',
            fifth_section_content_pl: '',

            first_section_image_en: '',
            first_section_title_en: '',
            first_section_content_en: '',
            second_section_title_en: '',
            second_section_content_en: '',
            third_section_title_en: '',
            third_section_content_en: '',
            third_section_box_image_en: '',
            third_section_box_title_en: '',
            third_section_box_content_en: '',
            third_section_box_link_en: '',
            fourth_section_title_en: '',
            fourth_section_content_en: '',
            fifth_section_title_en: '',
            fifth_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/contact-us-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.contactUsData = response.data || { items_pl: [], items_en: [] };
                console.log(this.contactUsData)
                if (this.contactUsData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.contactUsData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.contactUsData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.contactUsData.items_pl[0].first_section_image;
                    this.second_section_title_pl = this.contactUsData.items_pl[0].second_section_title;
                    this.second_section_content_pl = this.contactUsData.items_pl[0].second_section_content;
                    this.third_section_title_pl = this.contactUsData.items_pl[0].third_section_title;
                    this.third_section_content_pl = this.contactUsData.items_pl[0].third_section_content;
                    this.third_section_box_image_pl = API_URL + this.contactUsData.items_pl[0].third_section_box_image;
                    this.third_section_box_title_pl = this.contactUsData.items_pl[0].third_section_box_title;
                    this.third_section_box_content_pl = this.contactUsData.items_pl[0].third_section_box_content;
                    this.third_section_box_link_pl = this.contactUsData.items_pl[0].third_section_box_link;
                    this.fourth_section_title_pl = this.contactUsData.items_pl[0].forth_section_title;
                    this.fourth_section_content_pl = this.contactUsData.items_pl[0].forth_section_content;
                    this.fifth_section_title_pl = this.contactUsData.items_pl[0].fifth_section_title;
                    this.fifth_section_content_pl = this.contactUsData.items_pl[0].fifth_section_content;
                }
                console.log(this.first_section_image_pl)

                if (this.contactUsData.items_en[0].active === true) {
                    this.first_section_title_en = this.contactUsData.items_en[0].first_section_title;
                    this.first_section_content_en = this.contactUsData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.contactUsData.items_en[0].first_section_image;
                    this.second_section_title_en = this.contactUsData.items_en[0].second_section_title;
                    this.second_section_content_en = this.contactUsData.items_en[0].second_section_content;
                    this.third_section_title_en = this.contactUsData.items_en[0].third_section_title;
                    this.third_section_content_en = this.contactUsData.items_en[0].third_section_content;
                    this.third_section_box_image_en = API_URL + this.contactUsData.items_en[0].third_section_box_image;
                    this.third_section_box_title_en = this.contactUsData.items_en[0].third_section_box_title;
                    this.third_section_box_content_en = this.contactUsData.items_en[0].third_section_box_content;
                    this.third_section_box_link_en = this.contactUsData.items_en[0].third_section_box_link;
                    this.fourth_section_title_en = this.contactUsData.items_en[0].forth_section_title;
                    this.fourth_section_content_en = this.contactUsData.items_en[0].forth_section_content;
                    this.fifth_section_title_en = this.contactUsData.items_en[0].fifth_section_title;
                    this.fifth_section_content_en = this.contactUsData.items_en[0].fifth_section_content;
                }
            })
            .catch(error => {
                console.error('Error fetching Contact US data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}

</script>

<style>
@import '@/css/main.css';

.columns-6-section {
    margin-left: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-6-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

.columns-4-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
</style>