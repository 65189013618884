<template>
  <footer>
    <div class="main-footer">
      <div class="container">
        <div class="row">
          <div v-for="menuItem in currentLanguage === 'pl' ? footerData.items_pl : footerData.items_en" :key="menuItem.id"
            class="col-md-3 col-sm-6 col-xs-12">
            <h5 style="text-align: left;">{{ menuItem.section_title }}</h5>
            <div class="textwidget" style="text-align: left;">
              <div v-html="menuItem.item_title" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-footer">
      <div v-if="currentLanguage === 'pl'" class="container text-center">
        ©Copyright II Renewable Energy Supply Poland Sp. z o.o. {{ currentYear }}. Wszelkie prawa zastrzeżone.
      </div>
      <div v-else class="container text-center">
        ©Copyright Ingka Investments Poland {{ currentYear }}. All author rights are reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';


export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),

      footerData: {},
      section_title_pl: '',
      section_title_en: '',
      item_title_pl: '',
      item_title_en: '',

    };
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.getCurrentLanguage;
    },
  },
  created() {
    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }
  },
  mounted() {
    const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
    const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

    axios.get(`${API_URL}/api/footer-content`, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    })
      .then(response => {
        this.footerData = response.data || { items_pl: [], items_en: [] };
        // if (this.footerData.items_pl[0].active === true) {
        //   this.section_title_pl = this.footerData.items_pl[0].section_title;
        //   this.item_title_pl = this.footerData.items_pl[0].item_title;
        // }
        // if (this.footerData.items_en[0].active === true) {
        //   this.section_title_en = this.footerData.items_en[0].section_title;
        //   this.item_title_en = this.footerData.items_en[0].item_title;
        // }

      })
      .catch(error => {
        console.error('Error fetching Footer data:', error);
        console.log('Error Details:', error.response); // Log the full error object
      });


    const savedLanguage = localStorage.getItem("currentLanguage");
    if (savedLanguage) {
      this.$store.dispatch("toggleLanguage");
    }
  }
}

</script>
    
<style >
@import '@/css/footer.css';

.no-underline {
  text-decoration: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}
</style>