<template>
    <CookieWarning v-if="currentLanguage === 'pl'" :section_title="section_title_pl" :cookie_policy="cookie_policy_pl"/> 
    <CookieWarning v-else :section_title="section_title_en" :cookie_policy="cookie_policy_en"/>

</template>

<script>
import axios from 'axios';
import CookieWarning from '@/components/CookieWarning.vue';

export default {
    components: {
        CookieWarning
    },
    data() {
        return {
            cookieData: {
                items_pl: [{ section_title: '', cookie_policy: '' }],
                items_en: [{ section_title: '', cookie_policy: '' }],
            },
            section_title_pl: '',
            section_title_en: '',
            cookie_policy_pl: '',
            cookie_policy_en: '',

        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/cookie-policy-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.cookieData = response.data || { items_pl: [], items_en: [] };

                if (this.cookieData && (this.cookieData.items_pl.length > 0 || this.cookieData.items_en.length > 0)) {
                    const plItem = this.cookieData.items_pl[0];
                    const enItem = this.cookieData.items_en[0];

                    if (plItem && plItem.active === true) {
                        this.section_title_pl = plItem.section_title;
                        this.cookie_policy_pl = plItem.cookie_policy;
                    }

                    if (enItem && enItem.active === true) {
                        this.section_title_en = enItem.section_title;
                        this.cookie_policy_en = enItem.cookie_policy;
                    }
                }

                
            })
            .catch(error => {
                console.error('Error fetching cookie warning data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style></style>