<template>
    <aside v-if="showCookieComply" class="cookie-comply">
        <div class="cookie-comply__header">
            <slot name="header">
                <h3 class="cookie-comply__header-title">{{ section_title }}</h3>
                <p class="cookie-comply__header-description"></p>
                <div v-html="cookie_policy" class="cookie-comply__header-description"></div>       
            </slot>
        </div>

        <div class="cookie-comply__actions">
            <CookieWarningButton class-name="cookie-comply__button-accept" @handleClick="handleAcceptAll">
                {{ acceptAllLabel }}
            </CookieWarningButton>
        </div>
    </aside>
</template>

<script>
import CookieWarningButton from './CookieWarningButton.vue';

export default {
    props: {
        section_title: { type: String, default: "Cookie settings" },
        cookie_policy: {
            type: String,
            default: "We use cookies and similar technologies to help personalize content and offer a better experience. You can opt to customize them by clicking the preferences button.",
        },
        acceptAllLabel: { type: String, default: "Accept All" },
        target: { type: String, default: "body" }
    },
    emits: ["on-accept-all-cookies", "on-save-cookie-preferences"],
    data() {
        return {
            showCookieComply: true,
        };
    },
    mounted() {
        if (localStorage.getItem("cookie-comply")) {
            this.showCookieComply = false;
        }
    },
    methods: {
        handleAcceptAll() {
            this.showCookieComply = false;
            localStorage.setItem("cookie-comply", "all");
            this.$emit("on-accept-all-cookies");
        },
    },
    components: { CookieWarningButton }
}
</script>

<style>
:root {
    --spacing-sm: 8px;
    --spacing-md: 14px;
    --spacing-lg: 20px;
    --color-green: #00c58e;
    --color-white: #ffffff;
    --box-shadow: 0 1px 6px 1px rgb(0 0 0 / 10%), 0 1px 7px 1px rgb(0 0 0 / 6%);
    --border-radius: 8px;
    --background-overlay: rgba(34, 34, 34, 0.3);
    --border-color-lightest: 1px solid rgba(200, 200, 200, 0.2);
    --border-color-light: 1px solid rgba(0, 0, 0, 0.1);
}

body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.cookie-comply {
    display: grid;
    grid-gap: var(--spacing-lg);
    grid-template-columns: 2fr minmax(20%, 25%);
    position: absolute;
    bottom: var(--spacing-sm);
    left: var(--spacing-sm);
    right: var(--spacing-sm);
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

@media (max-width: 991px) {
    .cookie-comply {
        grid-template-columns: none;
    }
}

.cookie-comply__header {
    justify-self: flex-start;
    text-align: initial;
}

.cookie-comply__header-title,
.cookie-comply__header-description {
    margin: 0;
}

.cookie-comply__header-title {
    margin-bottom: var(--spacing-sm);
}

.cookie-comply__header-description {
    line-height: 20px;
}

.cookie-comply__actions {
    display: grid;
    grid-gap: var(--spacing-lg);
    grid-template-columns: 1fr auto;
    align-self: center;
}

@media (max-width: 480px) {
    .cookie-comply__header {
        margin-bottom: var(--spacing-sm);
    }

    .cookie-comply__actions {
        grid-template-columns: auto;
    }
}

.cookie-comply__button-accept {
    background-color: var(--color-green);
    color: var(--color-white);
    border: none;
}


.cookie-comply__button-accept {
    background-color: #0058A3;
}
</style>