<template>
    <NavbarMain :first-page="true" />
    <div v-if="loading">Loading...</div>

    <div v-else>
        <BannerFirstPage v-if="currentLanguage === 'pl'" :banner_image="banner_image_pl" :banner_title="banner_title_pl"
            :banner_button_text="banner_button_text_pl" :banner_button_link="banner_button_link_pl" />
        <BannerFirstPage v-else :banner_image="banner_image_en" :banner_title="banner_title_en"
            :banner_button_text="banner_button_text_en" :banner_button_link="banner_button_link_en" />

        <ContentWithPhoto v-if="currentLanguage === 'pl'" :content-photo_image="first_section_image_pl"
            :content-photo_title="first_section_title_pl" :content-photo_content1="first_section_content_pl"
            :content-photo_content2="first_section_content2" />
        <ContentWithPhoto v-else :content-photo_image="first_section_image_en" :content-photo_title="first_section_title_en"
            :content-photo_content1="first_section_content_en" :content-photo_content2="first_section_content2" />

        <div class="columns-4-section">
            <PhotoCardsHeader v-if="currentLanguage === 'pl'" :section_title="second_section_title_pl"
                :section_content="second_section_content_pl" />
            <PhotoCardsHeader v-else :section_title="second_section_title_en"
                :section_content="second_section_content_en" />


            <PhotoCards v-if="currentLanguage === 'pl'" :section_image="second_section_image_pl"
                :button_title="second_section_title_pl" :button_text="second_section_button_text_pl"
                :button_link="second_section_button_link_pl" />
            <PhotoCards v-else :section_image="second_section_image_en" :button_title="second_section_title_en"
                :button_text="second_section_button_text_en" :button_link="second_section_button_link_en" />
        </div>
        <br />
        <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="third_section_banner_image_pl"
            :content="third_section_banner_title_pl" />
        <WidePhotoComponent v-else :photo="third_section_banner_image_en" :content="third_section_banner_title_en" />

        <div class="columns-6-section">
            <CardSection v-if="currentLanguage === 'pl'" :content-title="fourth_section_title_box_one_pl"
                :content-sentance="fourth_section_content_box_one_pl" />
            <CardSection v-else :content-title="fourth_section_title_box_one_en"
                :content-sentance="fourth_section_content_box_one_en" />

            <CardSection v-if="currentLanguage === 'pl'" :content-title="fourth_section_title_box_two_pl"
                :content-sentance="fourth_section_content_box_two_pl" />
            <CardSection v-else :content-title="fourth_section_title_box_two_en"
                :content-sentance="fourth_section_content_box_two_en" />

            <CardSection v-if="currentLanguage === 'pl'" :content-title="fourth_section_title_box_three_pl"
                :content-sentance="fourth_section_content_box_three_pl" />
            <CardSection v-else :content-title="fourth_section_title_box_three_en"
                :content-sentance="fourth_section_content_box_three_en" />
        </div>

    </div>


    <CookieWarningView />
    <FooterPage />
</template>

<script>
import axios from 'axios';

import BannerFirstPage from '@/components/BannerFirstPage.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';
import ContentWithPhoto from '@/components/ContentWithPhoto.vue'
import CardSection from '@/components/CardSection.vue'
import PhotoCards from '@/components/PhotoCards.vue';
import CookieWarningView from './CookieWarningView.vue';
import PhotoCardsHeader from '@/components/PhotoCardsHeader.vue';

export default {
    components: {
        NavbarMain,
        BannerFirstPage,
        WidePhotoComponent,
        FooterPage,
        ContentWithPhoto,
        CardSection,
        PhotoCards,
        PhotoCardsHeader,
        CookieWarningView
    },
    data() {
        return {

            image: require('@/assets/404.jpg'),

            loading: true, // Add loading state

            homepageData: {
                items_pl: [{
                    id: '',
                    page_name: '',
                    banner_image: '',
                    banner_title: '',
                    banner_button_text: '',
                    banner_button_link: '',

                    first_section_image: '',
                    first_section_title: '',
                    first_section_content: '',

                    second_section_image: '',
                    second_section_title: '',
                    second_section_content: '',
                    second_section_button_text: '',
                    second_section_button_link: '',

                    third_section_banner_image: '',
                    third_section_banner_title: '',
                    third_section_banner_button_text: '',
                    third_section_banner_button_link: '',

                    active: '',

                    fourth_section_title_box_one: '',
                    fourth_section_content_box_one: '',
                    fourth_section_button_box_one: '',
                    fourth_section_button_link_box_one: '',
                    fourth_section_title_box_two: '',
                    fourth_section_content_box_two: '',
                    fourth_section_button_box_two: '',
                    fourth_section_button_link_box_two: '',
                    fourth_section_title_box_three: '',
                    fourth_section_content_box_three: '',
                    fourth_section_button_box_three: '',
                    fourth_section_button_link_box_three: ''
                }],
                items_en: [{
                    id: '',
                    page_name: '',
                    banner_image: '',
                    banner_title: '',
                    banner_button_text: '',
                    banner_button_link: '',

                    first_section_image: '',
                    first_section_title: '',
                    first_section_content: '',

                    second_section_image: '',
                    second_section_title: '',
                    second_section_content: '',
                    second_section_button_text: '',
                    second_section_button_link: '',

                    third_section_banner_image: '',
                    third_section_banner_title: '',
                    third_section_banner_button_text: '',
                    third_section_banner_button_link: '',

                    active: '',

                    fourth_section_title_box_one: '',
                    fourth_section_content_box_one: '',
                    fourth_section_button_box_one: '',
                    fourth_section_button_link_box_one: '',
                    fourth_section_title_box_two: '',
                    fourth_section_content_box_two: '',
                    fourth_section_button_box_two: '',
                    fourth_section_button_link_box_two: '',
                    fourth_section_title_box_three: '',
                    fourth_section_content_box_three: '',
                    fourth_section_button_box_three: '',
                    fourth_section_button_link_box_three: ''
                }]
            }
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    methods: {
        fetchData() {
            const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
            const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;
            // console.log(`Constructed URL: ${API_URL}/api/home-content`);

            axios.get(`${API_URL}/api/home-content`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            })
                .then(response => {
                    this.homepageData = response.data || { items_en: [], items_pl: [] };
                    // console.log(this.homepageData)
                    if (this.homepageData.items_pl[0].active === true) {
                        this.id_pl = this.homepageData.items_pl[0].id;
                        this.page_name_pl = this.homepageData.items_pl[0].page_name;

                        this.banner_image_pl = API_URL + this.homepageData.items_pl[0].banner_image;
                        this.banner_title_pl = this.homepageData.items_pl[0].banner_title;
                        this.banner_button_text_pl = this.homepageData.items_pl[0].banner_button_text;
                        this.banner_button_link_pl = this.homepageData.items_pl[0].banner_button_link;

                        this.first_section_image_pl = API_URL + this.homepageData.items_pl[0].first_section_image;
                        this.first_section_title_pl = this.homepageData.items_pl[0].first_section_title;
                        this.first_section_content_pl = this.homepageData.items_pl[0].first_section_content;

                        this.second_section_image_pl = API_URL + this.homepageData.items_pl[0].second_section_image;
                        this.second_section_title_pl = this.homepageData.items_pl[0].second_section_title;
                        this.second_section_content_pl = this.homepageData.items_pl[0].second_section_content;
                        this.second_section_button_text_pl = this.homepageData.items_pl[0].second_section_button_text;
                        this.second_section_button_link_pl = this.homepageData.items_pl[0].second_section_button_link;

                        this.third_section_banner_image_pl = API_URL + this.homepageData.items_pl[0].third_section_banner_image;
                        this.third_section_banner_title_pl = this.homepageData.items_pl[0].third_section_banner_title;
                        this.third_section_banner_button_text_pl = this.homepageData.items_pl[0].third_section_banner_button_text;
                        this.third_section_banner_button_link_pl = this.homepageData.items_pl[0].third_section_banner_button_link;

                        this.fourth_section_title_box_one_pl = this.homepageData.items_pl[0].fourth_section_title_box_one;
                        this.fourth_section_content_box_one_pl = this.homepageData.items_pl[0].fourth_section_content_box_one;
                        this.fourth_section_button_box_one_pl = this.homepageData.items_pl[0].fourth_section_button_box_one;
                        this.fourth_section_button_link_box_one_pl = this.homepageData.items_pl[0].fourth_section_button_link_box_one;
                        this.fourth_section_title_box_two_pl = this.homepageData.items_pl[0].fourth_section_title_box_two;
                        this.fourth_section_content_box_two_pl = this.homepageData.items_pl[0].fourth_section_content_box_two;
                        this.fourth_section_button_box_two_pl = this.homepageData.items_pl[0].fourth_section_button_box_two;
                        this.fourth_section_button_link_box_two_pl = this.homepageData.items_pl[0].fourth_section_button_link_box_two;
                        this.fourth_section_title_box_three_pl = this.homepageData.items_pl[0].fourth_section_title_box_three;
                        this.fourth_section_content_box_three_pl = this.homepageData.items_pl[0].fourth_section_content_box_three;
                        this.fourth_section_button_box_three_pl = this.homepageData.items_pl[0].fourth_section_button_box_three;
                        this.fourth_section_button_link_box_three_pl = this.homepageData.items_pl[0].fourth_section_button_link_box_three;

                    }

                    if (this.homepageData.items_en[0].active === true) {
                        this.id_en = this.homepageData.items_en[0].id;
                        this.page_name_en = this.homepageData.items_en[0].page_name;

                        this.banner_image_en = API_URL + this.homepageData.items_en[0].banner_image;
                        this.banner_title_en = this.homepageData.items_en[0].banner_title;
                        this.banner_button_text_en = this.homepageData.items_en[0].banner_button_text;
                        this.banner_button_link_en = this.homepageData.items_en[0].banner_button_link;

                        this.first_section_image_en = API_URL + this.homepageData.items_en[0].first_section_image;
                        this.first_section_title_en = this.homepageData.items_en[0].first_section_title;
                        this.first_section_content_en = this.homepageData.items_en[0].first_section_content;

                        this.second_section_image_en = API_URL + this.homepageData.items_en[0].second_section_image;
                        this.second_section_title_en = this.homepageData.items_en[0].second_section_title;
                        this.second_section_content_en = this.homepageData.items_en[0].second_section_content;
                        this.second_section_button_text_en = this.homepageData.items_en[0].second_section_button_text;
                        this.second_section_button_link_en = this.homepageData.items_en[0].second_section_button_link;

                        this.third_section_banner_image_en = API_URL + this.homepageData.items_pl[0].third_section_banner_image;
                        this.third_section_banner_title_en = this.homepageData.items_en[0].third_section_banner_title;
                        this.third_section_banner_button_text_en = this.homepageData.items_en[0].third_section_banner_button_text;
                        this.third_section_banner_button_link_en = this.homepageData.items_en[0].third_section_banner_button_link;

                        this.fourth_section_title_box_one_en = this.homepageData.items_en[0].fourth_section_title_box_one;
                        this.fourth_section_content_box_one_en = this.homepageData.items_en[0].fourth_section_content_box_one;
                        this.fourth_section_button_box_one_en = this.homepageData.items_en[0].fourth_section_button_box_one;
                        this.fourth_section_button_link_box_one_en = this.homepageData.items_en[0].fourth_section_button_link_box_one;
                        this.fourth_section_title_box_two_en = this.homepageData.items_en[0].fourth_section_title_box_two;
                        this.fourth_section_content_box_two_en = this.homepageData.items_en[0].fourth_section_content_box_two;
                        this.fourth_section_button_box_two_en = this.homepageData.items_en[0].fourth_section_button_box_two;
                        this.fourth_section_button_link_box_two_en = this.homepageData.items_en[0].fourth_section_button_link_box_two;
                        this.fourth_section_title_box_three_en = this.homepageData.items_en[0].fourth_section_title_box_three;
                        this.fourth_section_content_box_three_en = this.homepageData.items_en[0].fourth_section_content_box_three;
                        this.fourth_section_button_box_three_en = this.homepageData.items_en[0].fourth_section_button_box_three;
                        this.fourth_section_button_link_box_three_en = this.homepageData.items_en[0].fourth_section_button_link_box_three;

                        this.loading = false;

                    }
                })
                .catch(error => {
                    console.log('Error fetchig Homepage data:', error)
                    this.loading = false;

                });
        }
    },
    created() {
        this.fetchData(); // Move data fetching to the created hook
        const savedLanguage = localStorage.getItem('currentLanguage');
        if (savedLanguage) {
            this.$store.dispatch('toggleLanguage');
        }


    },

    mounted() {

        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style>
.columns-6-section {
    margin-left: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-6-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

.columns-4-section {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}



</style>