<template>
    <NavbarMain/>
    <ErrorPage/>
    <FooterPage/>
</template>

<script>
import NavbarMain from '@/components/NavbarMain.vue';
import ErrorPage from '@/components/ErrorPage.vue';
import FooterPage from '@/components/FooterPage.vue';

export default{ 
    components: {
        NavbarMain,
        ErrorPage,
        FooterPage
    },
}
</script>

<style>

</style>