<template>
    <NavbarMain/>

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl" :content="first_section_content_pl"/>
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en" :content="first_section_content_en"/>
   
    <FaqSection />

    <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="second_section_image_pl" :content="second_section_content_pl" />
    <WidePhotoComponent v-else :photo="second_section_image_en" :content="second_section_content_en" />
    
    <FooterPage/>
</template>

<script>
import axios from 'axios';
import FaqSection from '@/components/FaqSection.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';
export default {
    components: {
    NavbarMain,
    FooterPage,
    FaqSection,
    WidePhotoComponent,
    PresentationSection
},
    data() {
        return {
            faqData: {
                items_en: [{first_section_title: '', first_section_content: '', first_section_image: '',second_section_image: '', second_section_content: ''}],
                questions_en: [],
                items_pl: [{first_section_title: '', first_section_content: '', first_section_image: '',second_section_image: '', second_section_content: ''}],
                questions_pl: [],
            },
            first_section_title_pl: '',
            first_section_content_pl: '',
            first_section_image_pl: '',
            second_section_image_pl: '',
            second_section_content_pl: '',

            first_section_title_en: '',
            first_section_content_en: '',
            first_section_image_en: '',
            second_section_image_en: '',
            second_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = 'd4cdb302f1056d14cd0521b8224715ead8135891';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/faq-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.faqData = response.data || { items_pl: [], questions_pl: [], items_en: [], questions_en: []};

                if (this.faqData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.faqData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.faqData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.faqData.items_pl[0].first_section_image;
                    this.second_section_image_pl = API_URL + this.faqData.items_pl[0].second_section_image;
                    this.second_section_content_pl = this.faqData.items_pl[0].second_section_content;
                }
                if (this.faqData.items_en[0].active === true) {
                    this.first_section_title_en = this.faqData.items_en[0].first_section_title;
                    this.first_section_content_en = this.faqData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.faqData.items_en[0].first_section_image;
                    this.second_section_image_en = API_URL + this.faqData.items_en[0].second_section_image;
                    this.second_section_content_en = this.faqData.items_en[0].second_section_content;
                }
            })
            .catch(error => {
                console.error('Error fetching FAQ data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style>

</style>